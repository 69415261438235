<template>
  <RescheduleJobsStyled>
    <PanelMolecule :count="scheduleCount" :title="'Schedules'">
      <ApolloQuery :query="RESCHEDULES_QUERY" :variables="variables" :tag="null" slot="main" @result="setCount($event)">
        <template v-slot="{ result: { loading, error, data } }">
          <div v-if="loading" class="loading">Loading...</div>
          <div v-else-if="error" class="error">{{ error }}</div>
          <template v-else-if="data && data.schedules">
            <RescheduleJobsTable :schedules="data.schedules" @set-reschedule-ids="rescheduleIds = $event" />
          </template>
          <div v-else class="no-result">No schedules found.</div>
        </template>
      </ApolloQuery>
    </PanelMolecule>
  </RescheduleJobsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import PanelMolecule from '@/components/Atomic/Molecules/PanelMolecule'
import RescheduleJobsTable from './RescheduleJobs/RescheduleJobsTable.vue'
import RESCHEDULES_QUERY from '#/graphql/operations/calendars/reschedulesQuery.graphql'

const RescheduleJobsStyled = styled('div')`
  margin: 1rem;
`

export default {
  components: {
    RescheduleJobsStyled,
    PanelMolecule,
    RescheduleJobsTable,
  },
  data() {
    return {
      schedules: [],
      RESCHEDULES_QUERY,
      rescheduleIds: [],
      scheduleCount: undefined,
    }
  },
  computed: {
    variables() {
      return {
        where: {
          createShiftReport: {
            equals: true,
          },
        },
      }
    },
  },
  methods: {
    setCount($event) {
      if ($event?.data?.aggregateSchedule?._count?._all) {
        this.scheduleCount = $event?.data?.aggregateSchedule?._count?._all
      } else {
        this.scheduleCount = undefined
      }
    },
  },
}
</script>
