var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('RescheduleJobsStyled', [_c('PanelMolecule', {
    attrs: {
      "count": _vm.scheduleCount,
      "title": 'Schedules'
    }
  }, [_c('ApolloQuery', {
    attrs: {
      "slot": "main",
      "query": _vm.RESCHEDULES_QUERY,
      "variables": _vm.variables,
      "tag": null
    },
    on: {
      "result": function result($event) {
        return _vm.setCount($event);
      }
    },
    slot: "main",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _ref$result = _ref.result,
          loading = _ref$result.loading,
          error = _ref$result.error,
          data = _ref$result.data;
        return [loading ? _c('div', {
          staticClass: "loading"
        }, [_vm._v("Loading...")]) : error ? _c('div', {
          staticClass: "error"
        }, [_vm._v(_vm._s(error))]) : data && data.schedules ? [_c('RescheduleJobsTable', {
          attrs: {
            "schedules": data.schedules
          },
          on: {
            "set-reschedule-ids": function setRescheduleIds($event) {
              _vm.rescheduleIds = $event;
            }
          }
        })] : _c('div', {
          staticClass: "no-result"
        }, [_vm._v("No schedules found.")])];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }