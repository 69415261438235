<template>
  <ve-table
    ref="veTable"
    class="ve-table-foo"
    row-key-field-name="id"
    :columns="columns"
    :table-data="rows"
    :border-x="true"
    :border-y="true"
    :fixed-header="true"
    :max-height="600"
    :row-style-option="rowStyleOption"
    :cell-selection-option="cellSelectionOption"
    :checkbox-option="checkboxOption"
  />
</template>

<script>
import { CheckIcon } from 'vue-feather-icons'
import { VeTable } from 'vue-easytable'
import 'vue-easytable/libs/theme-dark/index.css'

import EntityLinkAtom from '@/components/Atomic/Atoms/EntityLinkAtom'
import { DateTime, Interval } from 'luxon'
import { getScheduleOccurencesInInterval } from '@common/utils/src'

export default {
  props: {
    schedules: {
      type: Array,
      required: true,
    },
  },
  components: {
    VeTable,
  },
  data() {
    return {
      rowStyleOption: {
        clickHighlight: true,
      },
      cellSelectionOption: {
        enable: false,
      },
      checkboxOption: {
        // row select change event
        selectedRowChange: ({ selectedRowKeys }) => {
          this.$emit('set-reschedule-ids', selectedRowKeys)
        },
        // selected all change event
        selectedAllChange: ({ selectedRowKeys }) => {
          this.$emit('set-reschedule-ids', selectedRowKeys)
        },
      },
    }
  },
  computed: {
    columns() {
      return [
        {
          field: '',
          key: 'a',
          type: 'checkbox',
          title: '',
          width: 50,
          align: 'center',
        },
        {
          field: 'name',
          key: 'n',
          title: `Name`,
          align: 'left',
          renderBodyCell: ({ row }) => {
            const { id, __typename, name } = row
            if (!id || !__typename || !name) {
              return `something missing`
            }
            return <EntityLinkAtom type={__typename} id={id} label={name} />
          },
        },
        {
          field: 'calendar.name',
          key: 'c',
          title: `Calendar`,
          align: 'left',
          renderBodyCell: ({ row }) => {
            const { id, __typename, name } = row.calendar || {}
            if (!id || !__typename || !name) {
              return `something missing`
            }
            return <EntityLinkAtom type={__typename} id={id} label={name} />
          },
        },
        {
          field: 'useTimezoneInRSchedule',
          key: 't',
          title: 'Use tz v2',
          renderBodyCell: ({ row }) => {
            return row.useTimezoneInRSchedule ? <CheckIcon /> : '-'
          },
        },
        {
          field: 'nextJob',
          key: 'j',
          title: 'Next Job',
          renderBodyCell: ({ row }) => {
            return row.nextJob?.id ? <EntityLinkAtom id={row.nextJob.id} type={'Job'} label={row.nextJob.id} /> : '-'
          },
        },
        {
          field: 'missing',
          key: 'm',
          title: 'Missing ShiftReports',
          align: 'center',
        },
        {
          field: 'lastShiftReportCreatedAt',
          key: 'l',
          title: 'Last ShiftReport created at',
          align: 'center',
        },
      ]
    },
    rows() {
      return this.schedules.map(schedule => {
        const lastShiftReportCreatedAt = schedule.shiftReports[0]?.createdAt || null
        const lastShiftReportCreatedAtDt = lastShiftReportCreatedAt ? DateTime.fromISO(lastShiftReportCreatedAt) : null
        const minStartOfRecurrences = schedule.recurrences.reduce((minDt, recurrence) => {
          const dt = DateTime.fromISO(recurrence.start)
          if (dt.toMillis() <= minDt.toMillis()) {
            return dt
          }
          return minDt
        }, DateTime.now())
        const start = DateTime.isDateTime(lastShiftReportCreatedAtDt) ? lastShiftReportCreatedAtDt : minStartOfRecurrences
        const end = DateTime.now()
        const interval = Interval.fromDateTimes(start, end)
        const missingOccurrences = getScheduleOccurencesInInterval(schedule, interval)
        return {
          ...schedule,
          missing: missingOccurrences.length,
          lastShiftReportCreatedAt,
        }
      })
    },
    // tableData() {
    //   const rows = this.schedules.map(schedule => ({
    //     id: schedule.id,
    //     name: schedule.name,
    //     nextJob: schedule.nextJob,
    //     useTimezoneInRSchedule: schedule.useTimezoneInRSchedule,
    //   }))
    //   return rows
    // },
  },
}
</script>
