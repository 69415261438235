var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ve-table', {
    ref: "veTable",
    staticClass: "ve-table-foo",
    attrs: {
      "row-key-field-name": "id",
      "columns": _vm.columns,
      "table-data": _vm.rows,
      "border-x": true,
      "border-y": true,
      "fixed-header": true,
      "max-height": 600,
      "row-style-option": _vm.rowStyleOption,
      "cell-selection-option": _vm.cellSelectionOption,
      "checkbox-option": _vm.checkboxOption
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }